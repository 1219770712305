<template>
    <b-overlay :show='showLoader'>
        <b-card>
            <b-row>
                <b-col><h3>{{ $t("general.search_papers") }}</h3></b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <label for="school_years">{{ $t('general.school_year') }}</label>
                    <v-select id='school_years' :clearable='false' :options='years' label="Name" v-model="selected_year"></v-select>
                </b-col>
                <b-col sm="6">
                    <label for="school_types">{{ $t('general.type') }}</label>
                    <v-select id='school_types' :clearable='false' :options='schoolTypes' label="Name" v-model="selectedSchoolType"></v-select>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col class='text-right'  v-if='arePapersLoaded'>
                    <b-button class="ml-1" v-if="generateShow" @click='generate' variant="primary">{{ $t('general.generate_results') }}</b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if='arePapersLoaded'>
            <b-row>
                <b-col cols='12' sm='6'>
                    <h3>{{ $t('general.papers_list_prijavljeno') }}</h3>
                </b-col>
                <b-col cols='12' sm='6' class="text-center text-sm-right">
                    <b-button variant="secondary" class="ml-2" @click="prijavnicaModal">{{ $t('general.schoolForm') }}</b-button>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <vue-good-table 
                        ref="table" 
                        :columns='columns' 
                        :rows='paperPrijavljeno'
                        styleClass="vgt-table striped condensed"
                        :pagination-options="{
                            enabled: true,
                            perPage: pageLength,
                        }">

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'TypeID'">
                                <p v-if='props.row.TypeID === 1'>
                                    Raziskovalna naloga
                                </p>
                                <p v-else-if="props.row.TypeID === 2">
                                    Inovacijski predlog
                                </p>
                            </span>
                            <span v-else-if="props.column.field == 'ID'">
                                <div class="d-flex justify-content-center">
                                    <b-button @click="$router.push(`/admin/researchPapers/${props.row.ID}`)" variant="info"><feather-icon icon="InfoIcon"/></b-button>
                                    <b-button class="ml-1" v-if='props.row.StateID !== 1' @click="pointsModal = true; paperId = props.row.ID" variant="secondary"><feather-icon icon="PlusIcon"/></b-button>
                                </div>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>

                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >

                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        {{ (($refs.table) ? $refs.table.currentPage : 0) * pageLength - (pageLength - 1) }} - {{ props.total - (($refs.table) ? $refs.table.currentPage : 0) * pageLength > 0 ? (($refs.table) ? $refs.table.currentPage : 0) * pageLength : props.total }} {{ $t('general.of') }} {{ props.total }}
                                    </span>

                                    <b-dropdown
                                        variant="none"
                                        no-caret>

                                        <template v-slot:button-content>
                                            <span>({{ $t('general.per_page') }}: {{pageLength}}) </span>
                                            <feather-icon
                                                icon="ChevronDownIcon"
                                                size="16"
                                                class="text-body align-middle mr-25"
                                            />
                                        </template>

                                        <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                                            {{ length }}
                                        </b-dropdown-item>

                                    </b-dropdown>
                                </div>
                                <div>
                                    <b-pagination
                                        :value="1"
                                        :total-rows="props.total"
                                        :per-page="pageLength"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>

                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-card>

        <b-card v-if='arePapersLoaded'>
            <b-row>
                <b-col cols='12' sm='6'>
                    <h3>{{ $t('general.papers_list_submit') }}</h3>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <vue-good-table
                        ref="tableSub"
                        :columns='columns'
                        :rows='paperSubmited'
                        styleClass="vgt-table striped bordered condensed"
                        :pagination-options="{
                            enabled: true,
                            perPage: pageLength,
                        }">

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'TypeID'">
                                <p v-if='props.row.TypeID === 1'>
                                    Raziskovalna naloga
                                </p>
                                <p v-else-if="props.row.TypeID === 2">
                                    Inovacijski predlog
                                </p>
                            </span>
                            <span v-else-if="props.column.field == 'ID'">
                                <div class="d-flex justify-content-center">
                                    <b-button @click="$router.push(`/admin/researchPapers/${props.row.ID}`)" variant="info"><feather-icon icon="InfoIcon"/></b-button>
                                    <b-button class="ml-1" v-if='props.row.StateID !== 1' @click="pointsModal = true; paperId = props.row.ID" variant="secondary"><feather-icon icon="PlusIcon"/></b-button>
                                </div>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>

                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >

                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        {{ (($refs.tableSub) ? $refs.tableSub.currentPage : 0) * pageLengthSub - (pageLengthSub - 1) }} - {{ props.total - (($refs.tableSub) ? $refs.tableSub.currentPage : 0) * pageLengthSub > 0 ? (($refs.tableSub) ? $refs.tableSub.currentPage : 0) * pageLengthSub : props.total }} {{ $t('general.of') }} {{ props.total }}
                                    </span>

                                    <b-dropdown
                                        variant="none"
                                        no-caret>

                                        <template v-slot:button-content>
                                            <span>({{ $t('general.per_page') }}: {{pageLengthSub}}) </span>
                                            <feather-icon
                                                icon="ChevronDownIcon"
                                                size="16"
                                                class="text-body align-middle mr-25"
                                            />
                                        </template>

                                        <b-dropdown-item :key="index" v-for="(length, index) in lengthsSub" @click.native="setPageLengthSub(length)">
                                            {{ length }}
                                        </b-dropdown-item>

                                    </b-dropdown>
                                </div>
                                <div>
                                    <b-pagination
                                        :value="1"
                                        :total-rows="props.total"
                                        :per-page="pageLengthSub"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>

                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-card>

        <b-modal :title="$t('general.set_points')" v-model="pointsModal" centered hide-footer>
            <template #default>
                <label for='points'>Točke</label>
                <b-form-input id='points' type="number" v-model.number="points"></b-form-input>
                <b-button class="mt-1 float-right" type="submit" variant="primary" @click="addPoints">{{ $t('general.save') }}</b-button>
            </template>
        </b-modal>

        <b-modal :title="$t('general.schoolForm')" v-model="prijavnicaActive" centered hide-footer no-close-on-backdrop>
            <template #default>
                <label for='form'>{{ $t('general.school_list') }}</label>
                <v-select id='form' :clearable='false' :options='schools' label="Name" v-model="selectedSchool"></v-select>
                
                <label class="mt-1" for='form'>{{ $t('general.status') }}</label>
                <v-select :options="researchPaperStateSelectOptions" v-model="selectedState"></v-select>
                
                <b-button class="mt-1 float-right" type="submit" variant="primary" @click="generateForms">{{ $t('general.schoolForm') }}</b-button>
            </template>
        </b-modal>
    </b-overlay>
</template>
<script>
/* eslint-disable */ 

    import {BCol, BRow, BOverlay, BCard, BButton, BPagination, BDropdownItem, BDropdown, BFormInput} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {VueGoodTable} from 'vue-good-table'
    export default {
        components: {BCol, BRow, BOverlay, BCard, BButton, BPagination, BDropdownItem, BDropdown, BFormInput, vSelect, VueGoodTable},
        computed: {
            rows() {
                if (!this.papersResults) return 0
                return this.papersResults.length
            }
        },
        data() {
            return {
                showLoader: false,
                years: [],
                selected_year: null,
                schoolTypes: [],
                selectedSchoolType: null,
                statuses: [
                    {
                        label: this.$t('general.status_registered'),
                        value: 1
                    },
                    {
                        label: this.$t('general.status_submited'),
                        value: 2
                    }
                ],

                papersResults: [],
                paperPrijavljeno: [],
                paperSubmited: [],
                arePapersLoaded: false,

                columns: [
                    {
                        field: 'Place',
                        label: this.$t('general.place'),
                        filterOptions: {
                            enabled: true
                        },
                        type: 'number',
                    },
                    {
                        field: 'Title',
                        label:  this.$t('general.title'),
                        filterOptions: {
                            enabled: true
                        },
                        type: "text",
                    },
                    {
                        field: 'Authors',
                        // field: 'Avtorji',
                        label: this.$t('general.authors'),
                        filterOptions: {
                            enabled: true,
                        },
                        type: "text",
                    },
                    {
                        field: 'Mentors',
                        // field: 'Mentorji',
                        label: this.$t('general.menthors'),
                        filterOptions: {
                            enabled: true,
                        },
                    },
                    {
                        field: 'Fields',
                        // field: 'Podrocja',
                        label: "Področja",
                        filterOptions: {
                            enabled: true,
                        },
                        type: "text",
                    },
                    {
                        field: 'SchoolYear.Name',
                        label: this.$t('general.school_year'),
                        filterOptions: {
                            enabled: true
                        },
                        type: "text",
                    },
                    {
                        field: 'TypeID',
                        label: this.$t('general.type'),
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [
                                { value: 1, text: this.$t('general.type_raziskovalna') },
                                { value: 2, text: this.$t('general.type_inovative') }
                            ]
                        },
                        sortable: false,
                        type: "number",

                    },
                    {
                        field: 'Award',
                        label: this.$t('general.award'),
                        filterOptions: {
                            enabled: true
                        },
                        type: "text",
                    },
                    {
                        field: 'Points',
                        label: this.$t('general.number_of_points'),
                        filterOptions: {
                            enabled: true
                        },
                        type: 'number',
                    },
                    {
                        field: 'School.Name',
                        label: this.$t('general.school'),
                        filterOptions: {
                            enabled: true
                        },
                        type: "text",
                    },
                    {
                        field: 'ID',
                        label: '',
                        sortable: false,
                        hidden: false,
                        type: "number",
                    }

                ],

                pageLength: 10,
                lengths: [
                    '10',
                    '20',
                    '50',
                    '100'
                ],
                pageLengthSub: 10,
                lengthsSub: [
                    '10',
                    '20',
                    '50',
                    '100'
                ],
                areas: [],
                pointsModal: false,
                points: '',
                paperId: 0,
                generateShow: false,
                dataLoaded: false,
                prijavnicaActive: false,
                schools: [],
                selectedSchool: null,

                researchPaperStateSelectOptions: [
                    {
                        key: 1,
                        label: 'Prijavljene naloge'
                    },
                    {
                        key: 2,
                        label: 'Oddane naloge'
                    }
                ],
                selectedState: null
            }
        },
        methods: {
            addPoints() {
                const thisIns = this
                if (thisIns.points !== '') {
                    thisIns.showLoader = true
                    thisIns.$http.patch(`/v1/admin/addPoints/${  this.paperId}`, {Points: thisIns.points})
                        .then(() => {
                            thisIns.onLoadPapers()
                            thisIns.showLoader = false
                            thisIns.points = ''
                            thisIns.pointsModal = false
                        })
                        .catch(error => {
                            thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                            thisIns.showLoader = false
                        })
                } else {
                    thisIns.points = ''
                    thisIns.pointsModal = false
                }

            },
            getPodrocjeNazivFromArray(podrocja) {
                if (!podrocja) return ''
                return podrocja.map(p => { return p.Name }).join(', ')
            },
            getFullnameFromPerson(peopleArray) {
                if (!peopleArray) return ''
                return peopleArray.map(p => { return `${p.Firstname} ${p.Lastname}` }).join(', ')
            },
            getFullnameFromMentor(peopleArray) {
                if (!peopleArray) return ''

                // per request from ZPM, we removed ProfessionalTitle from displaying menthors name in table (21.2.2022)
                return peopleArray.map(p => { return `${p.Firstname} ${p.Lastname}` }).join(', ')
            },
            setPageLength(length) {
                this.pageLength = length
                this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
            },
            setPageLengthSub(length) {
                this.pageLengthSub = length
                this.$refs.tableSub.perPageChanged({currentPerPage:this.pageLengthSub})
            },
            getAreaName(areaId) {
                return areaId
                // return this.areas.find(a => a.PodrocjeID === areaId).Naziv
                // return this.areas.find(a => a.PodrocjeID === areaId).Naziv
            },
            async assembleParalelPromise() {
                const p1 = this.$http.get('/v1/admin/sola')
                // const p2 = this.$http.get('/v1/admin/podrocja')

                return {
                    schoolsResponse: await p1
                    // themesResponse: await p2
                }
            },
            onLoadPapers() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.papersResults = []
                thisIns.paperPrijavljeno = []
                thisIns.paperSubmited = []
                thisIns.$http.get(`/v1/admin/sola/${thisIns.selected_year.ID}/naloga`)
                    .then(res => {
                        if (res.data === null) thisIns.$printWarning('Ni nalog za izbrano šolsko leto!')
                        else {
                            thisIns.papersResults = res.data
                            for (const paper of res.data) {

                                if (paper.StateID === 1 && paper.School.TypeID === thisIns.selectedSchoolType.ID) thisIns.paperPrijavljeno.push(paper)
                                else if (paper.School.TypeID === thisIns.selectedSchoolType.ID) thisIns.paperSubmited.push(paper)

                                if(paper && paper.Authors){
                                    let authors_string = ""
                                    for(const author of paper.Authors){
                                        authors_string += author.Firstname + " " + author.Lastname + ", "
                                    }
                                    paper.Authors = authors_string.substring(0, authors_string.length - 2);
                                }

                                if(paper && paper.Fields){
                                    let fields_string = ""
                                    for(const field of paper.Fields){
                                        fields_string += field.Name + ", "
                                    }
                                    
                                    paper.Fields = fields_string.substring(0, fields_string.length - 2);
                                }

                                 if(paper && paper.Mentors){
                                    let mentors_string = ""
                                    for(const mentor of paper.Mentors){
                                        mentors_string += mentor.Firstname + " " + mentor.Lastname +  ", "
                                    }
                                    paper.Mentors = mentors_string.substring(0, mentors_string.length - 2);
                                }
                            }
                            
                            thisIns.paperPrijavljeno = thisIns.paperPrijavljeno.sort((a, b) => { 
                                // če ma samo eno temo (ni interdisciplinarno), sortira najprej po IDju vede, potem pa še po zasedenem mestu
                                
                                if(a.InterdisciplinaryID === 2 && b.InterdisciplinaryID === 2) return a.Fields[0].ID - b.Fields[0].ID || a.Place - b.Place

                                if(a.TypeID === 2 && b.TypeID === 2) return a.Place - b.Place

                                if(a.InterdisciplinaryID === 1 && b.InterdisciplinaryID === 1) return a.Place - b.Place

                                if(a.InterdisciplinaryID === 1 && b.InterdisciplinaryID === 2) return 1

                                if(a.InterdisciplinaryID === 2 && b.InterdisciplinaryID === 1) return -1

                                // če ima več ved, sortira po arrayu in potem po mestu
                                 return -1
                            }) 

                            thisIns.paperSubmited = thisIns.paperSubmited.sort((a, b) => ((a.Place > b.Place) ? 1 : -1))
                        }
                        thisIns.arePapersLoaded = true
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
                    .finally(function() {
                        thisIns.dataLoaded = true
                    })
            },
            getSchoolYears() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/admin/solskoLeto/')
                    .then((response) => {
                        thisIns.years = response.data
                        for (const year of response.data) {
                            if (year.Active === 1) {
                                thisIns.selected_year = year
                            }
                        }
                        thisIns.getSchoolTypes()

                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getSchoolTypes() {
                const thisIns = this
                thisIns.showLoader = true

                thisIns.$http.get('/v1/admin/vrstaSole')
                    .then(res => {
                        thisIns.schoolTypes = res.data
                        thisIns.selectedSchoolType = res.data[0]
                        thisIns.showLoader = false
                        thisIns.onLoadPapers()
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            generate() {
                const thisIns = this
                thisIns.showLoader = true

         

                thisIns.$http.patch(`/v1/admin/generirajMesta/${thisIns.selected_year.ID}/${thisIns.selectedSchoolType.ID}`)
                    .then(function() {
                        thisIns.$printSuccess(`${thisIns.$t('general.result_successfully_generated')}`)
                        thisIns.showLoader = false
                        thisIns.onLoadPapers()
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            prijavnicaModal() {
                const thisIns = this
                thisIns.prijavnicaActive = true
                thisIns.showLoader = true
                thisIns.schools = []

                thisIns.$http.get('/v1/admin/sola')
                    .then(res => {
                        //thisIns.schools = res.data
                        for (const school of res.data) {
                            if (school.Active === true) thisIns.schools.push(school)
                        }
                        thisIns.selectedSchool = res.data[0]
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            generateForms() {

                if (!this.selectedState) {
                    this.$printWarning(`${this.$t('general.select_state_warning')}`)
                    return
                }

                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get(`/v1/admin/prijavnicaPDF/${thisIns.selectedSchool.ID}?state_id=${this.selectedState.key}`)
                    .then(function(response) {
                        thisIns.downloadItem(response.data)
                        thisIns.showLoader = false
                        thisIns.prijavnicaActive = false
                    })
                    .catch(error => {
                        if (error.response.data.includes('no papers to generate')) thisIns.$printError('Za izbrano šolo ni nalog!')
                        else thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            downloadItem(dobljenID) {
                const url = `/api/v1/admin/prijavnicaPDFAdmin/${dobljenID}`
                window.open(`${url}.pdf`, '_blank')
            },
            showGenerate(val) {
                const today = this.moment()
                const endDateTurnIn = this.moment(val.EndDateTurnIn)
                this.generateShow = today.isAfter(endDateTurnIn)
            }
        },
        created() {
            this.getSchoolYears()
        },
        watch: {
            selected_year(val) {
                this.showGenerate(val)
                if (!this.dataLoaded) return
                this.onLoadPapers()
            },
            selectedSchoolType() {
                if (!this.dataLoaded) return
                this.onLoadPapers()
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
